<template src="./addVideoModal.html"></template>

<script>
import dmModalMixin from '../../../../../../dmFramework/dmJS/dmModalMixin';

export default {
    name: 'manage-videos-modal',
    mixins: [dmModalMixin],
    data() {
        return {
            dto: {
                Name: '',
                VideoSrc: {},
            },
        }
    },
    computed: {
        mode() {
            return this.modalData.mode;
        },
        lblTitle() {
          return 'Add Video'
        },
		sessionID() {
			return this.$store.state.ObservationModule.Session.SessionID;
		}
    },
    methods: {
        fileUploaded(e) {
            let videoFile = e.target.files[0];
			this.dto.Name = videoFile.name;
        },
        btnSaveOnClick() {
			let fileUploadElement = document.getElementById('videoFile');
			let videoFile = fileUploadElement.files[0];
			let formData = new FormData();
			formData.append('file', videoFile);

            this.$store.dispatch('ObservationModule/addVideo', {data: formData, sessionID: this.sessionID})
                .then(() => {
                    this.successClose(this.dto);
                })
        },
        btnCancelOnClick() {
			this.successClose();
        },
    },
}
</script>